import React, {useContext} from 'react';
import {Button, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled} from "@mui/material";
import {checklistItems} from "../const";
import AppContext from "../AppContext";

const Checklist = () => {

  const { checklistState, updateChecklistItem, resetChecklist} = useContext(AppContext);

  return <Wrapper>
    <Button
      sx={{ mb: 4 }}
      variant="contained"
      onClick={resetChecklist}
    >Очистити усе</Button>
    <List>
      {checklistItems.map((item, i) => <ListItem key={i} sx={{ pl: 0, pr: 0}}>
        <ListItemButton sx={{ pl: 0, pr: 0, pt: 0, pb: 0 }} onClick={() => updateChecklistItem(!checklistState[i], i)} dense>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={checklistState[i]}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': `checkbox-list-label-${i}` }}
              sx={{ pt: 0, pb: 0 }}
            />
          </ListItemIcon>
          <ListItemText id={`checkbox-list-label-${i}`} primary={item} />
        </ListItemButton>
      </ListItem>)}
    </List>
  </Wrapper>;
};

export default Checklist;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;
