import {Config, Report, ResultInto, StartRequest, Target} from "../types";
import {format} from "date-fns";

export const formatRequest = (report: Report, config: Config) => {
  const {
    startTime,
    endTime,
    objective,
    targets,
    ammos,
  } = report;
  const {
    crew,
    altitude,
    frequencies,
    attackArea,
    takeOffLocation,
    vehicleType,
    areaOfResponsibility,
  } = config;
  return `Екіпаж ${crew} 411 ОП БпС, який виконує завдання щодо ударної роботи та доставки гуманітарної допомоги (вода, медикаменти, продукти) в смузі відповідальності ${areaOfResponsibility}
Тип засобу: ${vehicleType}
Час: ${format(startTime, 'HH:mm')}
Час закінчення: ${format(endTime, 'HH:mm')}
Взліт: ${takeOffLocation}
Район ураження: ${attackArea}
Частоти: ${frequencies}
Висота: ${altitude}
Мета: ${objective}
${formatTargets(targets)}
Час ураження: ${calcAttackTime(startTime)}
Боєприпас: ${joinLabelByComma(ammos.map(ammo => `${ammo.ammoType}${ammo.ammoQuantity && ammo.ammoQuantity > 1 ? ` ${ammo.ammoQuantity} шт.` : ''}`))}
Детонатор: ${joinLabelByComma(ammos.map(ammo => `${ammo.detonator}${ammo.detonatorQuantity && ammo.detonatorQuantity > 1 ? ` ${ammo.detonatorQuantity} шт.` : ''}`))}
Ініціатор: ${joinLabelByComma(ammos.map(ammo => `${ammo.initiator}${ammo.initiatorQuantity && ammo.initiatorQuantity > 1 ? ` ${ammo.initiatorQuantity} шт.` : ''}`))}`
};

export const formatReport = (flightNumber: number, report: Report, config: Config) => {
  const {
    startTime,
    objective,
    targets,
    ammos,
    results,
  } = report;

  const {
    frequencies,
    altitude,
    attackArea,
  } = config;
  return `№ вильоту: ${String(flightNumber).padStart(4, '0')}
час, дата: ${format(startTime, 'HH:mm, dd.MM.yyyy')}
Район ураження: ${attackArea}
Частоти: ${frequencies}
Висота: ${altitude}
Мета: ${objective.trim()}
${formatTargets(targets)}
Боєприпас: ${joinLabelByComma(ammos.map(ammo => `${ammo.ammoType}${ammo.ammoQuantity && ammo.ammoQuantity > 1 ? ` ${ammo.ammoQuantity} шт.` : ''}`))}
Детонатор: ${joinLabelByComma(ammos.map(ammo => `${ammo.detonator}${ammo.detonatorQuantity && ammo.detonatorQuantity > 1 ? ` ${ammo.detonatorQuantity} шт.` : ''}`))}
Плата ініціації: ${joinLabelByComma(ammos.map(ammo => `${ammo.initiator}${ammo.initiatorQuantity && ammo.initiatorQuantity > 1 ? ` ${ammo.initiatorQuantity} шт.` : ''}`))}
Час ураження: ${calcAttackTime(startTime)}
${formatResults(results)}`;
};

const formatTargets = (targets: Target[]) => targets.reduce((acc, target, i) => acc + `Ціль${targets.length > 1 ? ' '+(i+1) : ''}: ${target.value}${target?.description ? ', ' + target.description : ''}${i !== targets.length -1 ? '\n' : ''}`, '');
const formatResults = (results: ResultInto[]) => results?.length === 1 ? `Результат: ${results[0]?.result}${results[0]?.description && ', ' + results[0].description}` :
  results.reduce((acc, result, i) => acc + `Результат ${i+1}: ${result.result}${result?.description && ', ' + result.description}${i !== results.length -1 ? '\n' : ''}`, '');

const joinLabelByComma = (arr: string[]) => arr.join(', ') || '-';

const calcAttackTime = (startTime: Date) => format(new Date(startTime.getTime() + 600000), 'HH:mm');

export const formatStartRequest = (config: Config, startRequestConfig: StartRequest) => `З ${format(startRequestConfig.startDate || new Date(), 'HH:mm dd.MM.yyyy')} по ${format(startRequestConfig.endDate || new Date(), 'HH:mm dd.MM.yy')},  Підрозділ - ЗвЗг 411 ОП БпС
2. Позивний: Зведений екіпаж ${config.crew}: склад групи: ${config.crewComposition.join(', ')}
3. Тип БпЛА і серійний №: ${config.vehicleType} №${startRequestConfig.serialNumber}
4. Час роботи: ${format(startRequestConfig.startDate || new Date(), 'HH:mm')} - ${format(startRequestConfig.endDate || new Date(), 'HH:mm')}
5. Точка злету: ${config.takeOffLocation}
6. Висота: ${startRequestConfig.altitude}
7. Частота: ${config.frequencies}
8. Р-н виконання завдань: Зона відповідальності ${config.areaOfResponsibility}, ${config.attackArea}
9. БК:  ${startRequestConfig.ammo.join(', ')}
10: Детонатор: ${startRequestConfig.detonators.length ? startRequestConfig.detonators.join(', ') : '-'}
11: Ініціатор: ${startRequestConfig.initiators.length ? startRequestConfig.initiators.join(', ') : '-'}
12. Контактний  телефон: ${startRequestConfig.contactPhone}
13. Стрім в Вежі: ${startRequestConfig.streamName}`;