import React, {useContext, useMemo} from 'react';
import AppContext from "../AppContext";
import {styled, Typography} from "@mui/material";
import {Results} from "../const";

type AmmoQuantity = {
  [x: string]: number;
}

const UsedAmmo = () => {

  const { reports } = useContext(AppContext);

  const usedAmmo = useMemo(() => {
    if(reports){
      return reports.reduce((obj: AmmoQuantity, report) => {
        report.ammos.forEach(ammo => {
          if(report.results.filter(result => result.result === Results.ReturnWithAmmo).length === 0){
            if(obj[ammo.ammoType]){
              obj[ammo.ammoType] += ammo.ammoQuantity || 1;
            }else{
              obj[ammo.ammoType] = ammo.ammoQuantity || 1;
            }
          }
        });

        return obj;
      }, {} as AmmoQuantity)
    }else{
      return {};
    }
  }, [reports]);

  return <Wrapper>
    <Typography variant="h5">Використане БК:</Typography>
    {Object.entries(usedAmmo).map(usedAmmoItem => <Typography key={usedAmmoItem[0]}>{usedAmmoItem[0]}: {usedAmmoItem[1]}</Typography>)}
  </Wrapper>
};

export default UsedAmmo;

const Wrapper = styled('div')`
`;